.outline-input-height {
    min-height: 0;
}

.priority-button {

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid #D2D1D4;
    border-radius: 3px;
    box-sizing: border-box;
    padding-left: 1rem;
    padding-right: 1rem;
}

.rotate-90 {
    transform: rotate(90deg);
}

.right-drawer-container {
    .input {
        border: 1px solid black;
        border-radius: 0.4rem;
        padding: 0.4rem;
        outline: none;
    }
}

.edit-employee {

    .MuiAccordionSummary-root,
    .MuiAccordionDetails-root {
        padding: 0 !important;
    }

    .fixed-head {
        position: fixed;
        top: 0;
        min-height: 45px;
        background: white;
        width: inherit;
        z-index: 1;
    }
}

.border-btn-disable {
    color: rgba(0, 0, 0, 0.26);
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.26) !important;
}