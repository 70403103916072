* {
  letter-spacing: 0px !important
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-style: normal !important;
  /* letter-spacing: 0px; */
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*---------------------------------------------------*/
/* fonts starts here */

legend {
  /* font-size: 0.85em !important; */
  color: #757575 !important;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.MuiFormLabel-asterisk,
.MuiInputLabel-asterisk {
  color: red;
}

.MuiSelect-select:focus {
  background-color: unset !important;
}

.MuiTabs-root {
  min-height: 48px !important;
}

.pb-fixed {
  padding-bottom: 30px;
}

.mb-fixed {
  margin-bottom: 30px;
}

.main-container {
  flex-grow: 1;
  padding-top: 48px;
}

/* .leftTabnew .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: rgba(51, 90, 255, 0.05);
  border-radius: 3%;
  color: rgb(51, 90, 255) !important;
} */

.leftTabnew {
  width: calc(100% - 24px);
}

.leftTabnew .css-10d9dml-MuiTabs-indicator {
  display: none;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  letter-spacing: normal !important;
}

/* header.MuiAppBar-root {
  background-color: white;
  color: black;
  max-height: 48px;
  box-shadow: rgb(0 0 0 / 12%) 0rem 0.125rem 0.125rem !important;
  z-index: 999;
} */

.css-10ltm04-MuiToolbar-root {
  min-height: 60px !important;
}

.MuiTabPanel-root {
  padding: 12px !important;
}

/* .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: solid #3f51b5 0px !important;
} */

.MuiTablePagination-selectLabel {
  display: none !important;
}

/* .MuiTabs-indicator {
  background-color: #fff !important;
} */
/* .checkbox-cross {
  line-height: 0.6em;
  background-color: gray;
  border-radius: 3px;
  color: white;
  width: 0.75em;
  height: 0.75em;
  margin: 3px;
} */

.display-center {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none !important;
}

.display-center:focus-visible {
  outline: none !important;
}

.list-popover .list-item {
  width: 100%;
  height: 48px;
  cursor: pointer;
}

.list-popover .list-item .list-item-icon {
  min-width: 2rem !important;
  color: inherit;
}

/* 
.list-item {
  position: relative;
}
.list-item::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 7.5px;
  background-color: black;
  top: 8px;
  left: 7px;
} */
.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.table-unknown {
  max-height: 350px;
  max-width: 99%;
  margin-bottom: 5px;
}

.table-unknown-tr {
  cursor: pointer;
  transition: 1s;
}

.table-unknown-tr:hover() {
  background-color: black;
  transition: 1s;
}

.table-unknown::-webkit-scrollbar {
  width: 5px;
}

.table-unknown::-webkit-scrollbar-track {
  display: none;
}

.table-unknown::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.363);
  border-radius: 15px;
}

/*sahud*/
.cursor-pointer {
  cursor: pointer !important;
}

.top-70px {
  top: 70px !important;
}

.b-0 {
  border: 0px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mt-1 {
  margin-top: 1vh !important;
}


.m-0 {
  margin: 0% !important;
}

.p-0 {
  padding: 0% !important;
}

.mp-0 {
  margin: 0px !important;
  padding: 0px !important;
}

.w-100 {
  width: 100% !important;
}

.w-48 {
  width: 48% !important;
}

.w-50 {
  width: 50% !important;
}

.w-70 {
  width: 70% !important;
}

.w-46 {
  width: 46% !important;
}

.w-30 {
  width: 30% !important;
}

.w-25 {
  width: 25% !important;
}

.w-75 {
  width: 75% !important;
}

.w-15 {
  width: 15% !important;
}

.w-85 {
  width: 85% !important;
}

.w-auto {
  width: auto !important;
}

.h-100 {
  height: 100% !important;
}

.h-inherit {
  height: inherit;
}

.h-100-vh {
  height: 100vh;
}

.wh-100 {
  height: 100% !important;
  width: 100% !important;
}

.mb-30 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px !important;
}

.start-flex {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.flex-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigationList {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.flex-start-end {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.flex-between-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.esiNote {
  font-size: 12px !important;
}

.redColor {
  color: red
}

.head-active {
  opacity: 1;
}

.head-item {
  opacity: 0.5;
}


.start-flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-column {
  display: flex;
  flex-direction: column;
}


.flex-row {
  display: flex;
  flex-direction: row;
}

.wrap-flex {
  flex-wrap: wrap;
}

.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background: white;
  width: 100%;
}

.end-flex {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-start-end {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

/**MUI Conflict classes**/
.border-btn {
  border: 1px solid !important;
}

.wh-vw-vh {
  width: 100vw;
  height: 100vh;
}

.overflow-hidden {
  overflow: hidden !important;
  scrollbar-width: none;
}

.cursor-pointer {
  cursor: pointer;
}

.drawer-open {
  width: 100vw;
  height: calc(100vh - 55px);
  background-color: #fff;
}

.border-top-radius-10 {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.mt-20 {
  margin-top: 20px;
}


.mr-15 {
  margin-right: 15px;
}

.title-bar {
  height: 56px !important;
  width: 100%;
}


.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}


.MuiInputBase-sizeSmall {
  min-height: auto;
}


.page-shadow {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  /* box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.08), 0 0 0px 1px rgba(0, 0, 0, 0.04), 2px 2px 6px 0px rgba(0, 0, 0, 0.08) !important; */
}

.head-title {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

/* input {
  height: 100% !important;
} */

.mx-height {
  max-height: calc(100vh - 265px);
  overflow: auto;
}

.mx-height-250 {
  max-height: calc(100vh - 250px);
  overflow: auto;
}

.mx-height-280 {
  max-height: calc(100vh - 280px);
  overflow: auto;
}

.mx-height-300 {
  max-height: calc(100vh - 300px);
  overflow: auto;
}

.mx-height-320 {
  max-height: calc(100vh - 320px);
  overflow: auto;
}




/* Hide scrollbar for Chrome, Safari and Opera */
.payroll-scroll::-webkit-scrollbar {
  display: none;
}

input[type=time]::-webkit-datetime-edit-ampm-field {
  display: none;
}

.margin-10 {
  margin: 10px
}

.uppercase {
  text-transform: uppercase;
}

.dragger-file-area {
  width: 100%;
  height: 65px;
  border: 2px dashed #80acff;
  background-color: #f4f8fc;
  padding: 5px;
  border-radius: 6px;
  cursor: pointer;
  line-height: 50px;
}

.spacing {
  padding: 1.25rem;
  padding-bottom: 10px;
}

.positon-sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
}

.strikethrough {
  position: relative;
  /* text-decoration: line-through;
  text-decoration-color: red; */
  /* Change the color of the strike-through */
  /* text-decoration-thickness: 3px; */
  /* Change the thickness of the strike-through */
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid rgba(27, 24, 24, 0.607);

  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}