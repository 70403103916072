.MuiMenu-root {
    .MuiMenu-list {
        max-height: 250px;
    }
}

.date-range-picker__container {
    svg {
        width: 30px;
        height: 28px;
    }

    .error {
        color: red;
        border-color: red !important;
    }

    .picker--icon {
        width: 100%;
        height: 42px;
        border-radius: 6px;
        font-size: 14px;
        padding: 11px 15px;
        border: 1px solid #aea9a9a1;

        &:focus {
            border: 1px solid black;
        }
    }

    .helper-text {
        margin-left: 2px;
        margin-top: 2px;
    }
}