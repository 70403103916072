.payment_box {
    min-width: 15rem;
    height: 70%;
    border: 2px solid;
    border-radius: 2.5rem;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    padding: 0.5rem;
    box-sizing: border-box;
    cursor: pointer;

    .rounded {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .price_box {
        font-weight: bold;
        font-size: 20px;
    }

    .green_price {
        color: #45E570;
    }

    .blue_price {
        color: #4D8BFF;
    }


    .red_price {
        color: #45E570;
    }

    .green_icon {
        background-color: #bdf1e4;
        color: #45E570
    }

    .blue_icon {
        background-color: #E6ECFF;
        color: #4D8BFF;
    }

    .red_icon {
        background-color: #E9FDF8;
        color: #45E570
    }
}

.green_border_color {
    border-color: #45E570 !important;
}

.red_border_color {
    border-color: #45E570 !important;
}

.blue_border_color {
    border-color: #4D8BFF !important;
}

.summary-margin {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.otp-notification-size {

    display: flex;
    align-items: center;
    column-gap: 0.2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.3rem;
    padding-right: 0.2rem;
    padding-left: 0.2rem;

    svg {
        font-size: 16px;
    }
}

.info-otp {
    border: 1px solid #E6ECFF;
    color: #80ACFF;
}

.error-otp {
    border: 1px solid red;
    color: red;
}