.status-badge {
    padding: 0.175rem;
    width: min-content;
    font-size: 0.875rem;
    font-weight: 500;
    text-align: center;
    border-radius: 0.25rem;
    height: 26px;
}

.pending {
    background-color: #FFFAE5;
    color: #FFDB4D;
}

.active {
    background-color: #E9FDF8;
    color: #45E570;
}

.reject {
    background-color: #FFCCCC;
    color: #FF3333;
}

.default {
    background-color: #FFF1E6;
    color: #DD7050;
}