.h-container {
    .outline-input-height {
        min-height: 0;
    }

    .priority-button {

        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #D2D1D4;
        border-radius: 3px;
        box-sizing: border-box;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .rotate-90 {
        transform: rotate(90deg);
    }

    .right-drawer-container {
        .input {
            width: 50%;
            outline: none;
        }
    }

    .edit-employee {

        .MuiAccordionSummary-root,
        .MuiAccordionDetails-root {
            padding: 0 !important;
        }
    }

    .icon-rounded {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #E6ECFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .icon-rounded-white {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #F9F9F9;
        opacity: 0.8;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        cursor: pointer;
    }

    .icon-rounded-back {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #F9F9F9;
        opacity: 0.8;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        cursor: pointer;
    }



    .icon-rounded-add {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #E6ECFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
        padding: 15px;
        margin-left: 5px;
        // border-radius: 8px;
    }

    .icon-rounded-yellow {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #FFFAE5;
        display: flex;
        align-items: center;
        justify-content: center;
        // cursor: pointer;
        padding: 15px;

        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    }

    .icon-rounded-red {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #faf1f2;
        opacity: 6%;
        display: flex;
        align-items: center;
        justify-content: center;
        // cursor: pointer;
        padding: 15px;

        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    }

    .icon-rounded-remove {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #FFCCCC;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .icon-rounded-transparent {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .leave-encash {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        .MuiInputBase-sizeSmall {
            min-height: auto;
        }
    }

    .miscellaniousEarningEdit {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;

        // padding-left:10px;
        .MuiInputBase-sizeSmall {
            min-height: auto;
        }
    }
}

//  .stepper-box-shadow {
//     box-shadow: 2px 2px 2px gray;
//  }