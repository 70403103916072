@font-face {
    font-family: 'Segoe UI Web (West European)';
    font-style: normal;
    font-weight: 100;
    src: url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-light.woff2')
            format('woff2'),
        url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-light.woff')
            format('woff');
}

@font-face {
    font-family: 'Segoe UI Web (West European)';
    font-style: normal;
    font-weight: 500;
    src: url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semilight.woff2')
            format('woff2'),
        url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semilight.woff')
            format('woff');
}

@font-face {
    font-family: 'Segoe UI Web (West European)';
    font-style: normal;
    font-weight: 400;
    src: url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff2')
            format('woff2'),
        url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff')
            format('woff');
}

@font-face {
    font-family: 'Segoe UI Web (West European)';
    font-style: normal;
    font-weight: 600;
    src: url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff2')
            format('woff2'),
        url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff')
            format('woff');
}

@font-face {
    font-family: 'Segoe UI Web (West European)';
    font-style: normal;
    font-weight: 700;
    src: url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-bold.woff2')
            format('woff2'),
        url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-bold.woff')
            format('woff');
}